import * as React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import ImageTextBlockWithCarousel from "@shared/imageTextBlock";
import Hero from "@shared/hero";
import { HeroCarousel } from "@global";
import SEO from "@shared/seo";
import Layout from "@shared/layout";

interface Props extends PageProps {
  data: Query;
  pageContext: { iban: string; slug: string };
}

const AboutPage = ({ data }: Props) => {
  const sanityAboutPage = data.allSanityAboutPage.nodes[0];

  if (sanityAboutPage == null) {
    return null;
  }

  return (
    <>
      <SEO seoData={sanityAboutPage.seo} />
      <Layout>
        {sanityAboutPage &&
        sanityAboutPage.additionalHeros &&
        sanityAboutPage.additionalHeros.length > 0 ? (
          <HeroCarousel
            hero={sanityAboutPage?.hero}
            additionalHeros={sanityAboutPage.additionalHeros}
          />
        ) : (
          <Hero sanityHero={sanityAboutPage?.hero} extendedHeight />
        )}
        <ImageTextBlockWithCarousel
          containerWidth="80%"
          data={sanityAboutPage.aboutContent}
        />
      </Layout>
    </>
  );
};

export default AboutPage;

export const query = graphql`
  query allSanityAbout($iban: String) {
    allSanityAboutPage(filter: { region: { iban: { eq: $iban } } }) {
      nodes {
        hero {
          ...sanityHero
        }
        additionalHeros {
          ...sanityHero
        }
        seo {
          ...sanitySeo
        }
        aboutContent {
          ... on SanityImageTextBlock {
            ...sanityImageTextBlock
          }
          ... on SanityFullWidthContentCarousel {
            ...sanityFullWidthContentCarousel
          }
          ... on SanityFullWidthTextBlock {
            ...sanityFullWidthTextBlock
          }
        }
      }
    }
  }
`;
